import { i18n } from '@lingui/core'
import {
  format as _format,
  formatDistanceToNowStrict as _formatDistanceToNowStrict,
  formatDistance,
  parseISO,
  formatDistanceStrict,
} from 'date-fns'
import { enUS, es, fr, ja, ko, ptBR, zhCN, zhTW } from 'date-fns/locale'

export const gammaLocaleToDateFnsLocaleMap = {
  en: enUS,
  'pt-br': ptBR,
  ja: ja,
  'zh-cn': zhCN,
  'zh-tw': zhTW,
  es: es,
  fr: fr,
  ko: ko,
}

export const getLocaleForDateFns = () => {
  return gammaLocaleToDateFnsLocaleMap[i18n.locale] || undefined
}

export const formatDate = (date: string, format: string) => {
  if (!date) return ''
  return _format(parseISO(date), format, { locale: getLocaleForDateFns() })
}

export const formatDateRelative = (date: string, baseDate?: string) => {
  if (!date) return ''
  return formatDistance(
    parseISO(date),
    baseDate ? parseISO(baseDate) : new Date(),
    { locale: getLocaleForDateFns() }
  )
}

export const formatDistanceToNowStrict = (date: string) => {
  if (!date) return ''
  return _formatDistanceToNowStrict(parseISO(date), {
    locale: getLocaleForDateFns(),
  })
}

export const formatTimeAgo = (date: string) => {
  if (!date) return ''
  return formatDistanceStrict(parseISO(date), new Date(), {
    locale: getLocaleForDateFns(),
    addSuffix: true,
    roundingMethod: 'floor',
  })
}
