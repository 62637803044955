import { Button, ButtonProps, Image, Link, Stack } from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'

import { useGetLocalizedMarketingSiteUrls } from 'modules/marketing_pages/hooks/useGetLocalizedMarketingSiteLinks'
import { useUserContext } from 'modules/user'
import { useSSRMounted } from 'utils/hooks'

import { MARKETING_SITE_LINKS } from '../../constants'
import { MarketingLanguageSwitcher } from '../MarketingLanguageSwitcher'
import { GAMMA_LOGO_FILE } from './colorConstants'
import { useMarketingHeaderUIContext } from './MarketingHeaderUIContext'
import { NavbarTextLink } from './shared/NavbarItems'

export type HeaderMode = 'dark' | 'light'

type PrimaryButtonProps = {
  children: React.ReactNode
  href: string
  buttonProps?: ButtonProps
}
const PrimaryButton = ({ children, href, buttonProps }: PrimaryButtonProps) => {
  return (
    <Button
      as={Link}
      href={href}
      maxHeight="32px"
      borderStyle="solid"
      colorScheme="gray"
      borderWidth="0.5px"
      fontSize="14px"
      borderColor="rgba(0, 0, 0, 0.24)"
      backgroundColor="#fff"
      boxShadow="0 4px 4px 0 rgb(0 0 0 / 5%)"
      fontWeight="700"
      borderRadius="100px"
      color="#3b3a40"
      _hover={{
        color: 'black',
        textDecoration: 'none',
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

const LoggedInNavItems = () => {
  return (
    <>
      <MarketingLanguageSwitcher />
      <PrimaryButton
        href="/"
        buttonProps={{ rightIcon: <FontAwesomeIcon icon={faArrowRight} /> }}
      >
        <Trans>Go to app</Trans>
      </PrimaryButton>
    </>
  )
}

const LoggedOutNavItems = () => {
  const { pricingUrl } = useGetLocalizedMarketingSiteUrls()

  return (
    <Stack
      alignItems="center"
      alignContent="center"
      spacing={8}
      direction="row"
    >
      <MarketingLanguageSwitcher />
      <NavbarTextLink href={pricingUrl}>
        <Trans>Pricing</Trans>
      </NavbarTextLink>
      <NavbarTextLink href={MARKETING_SITE_LINKS.careers}>
        <Trans>Careers</Trans>
      </NavbarTextLink>
      <NavbarTextLink href={MARKETING_SITE_LINKS.login}>
        <Trans>Login</Trans>
      </NavbarTextLink>
      <PrimaryButton href={MARKETING_SITE_LINKS.signup}>
        <Trans>Try for free</Trans>
      </PrimaryButton>
    </Stack>
  )
}

export const MarketingHeaderDesktop = () => {
  const { mode } = useMarketingHeaderUIContext()
  const { homeUrl } = useGetLocalizedMarketingSiteUrls()
  const { user, isUserLoading } = useUserContext()
  const showLoggedInNav = user && !isUserLoading

  const ssrMounted = useSSRMounted()

  return (
    <Stack direction="row" width="100%" justifyContent="space-between">
      <Link href={homeUrl} aria-current="page" as={NextLink}>
        <Image
          w="100px"
          src={GAMMA_LOGO_FILE[mode]}
          loading="lazy"
          alt="Gamma logo"
        />
      </Link>

      {ssrMounted && (
        <Stack
          direction="row"
          spacing={[4, 4, 8]}
          fontWeight="500"
          fontSize="sm"
          alignItems="center"
          transition="color 300ms ease"
        >
          {!user && isUserLoading ? (
            <></>
          ) : showLoggedInNav ? (
            <LoggedInNavItems />
          ) : (
            <LoggedOutNavItems />
          )}
        </Stack>
      )}
    </Stack>
  )
}
