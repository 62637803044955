import React, { useContext } from 'react'

export const MarketingHeaderUIContext = React.createContext<{
  mode: 'dark' | 'light'
  isFixed?: boolean
}>({
  mode: 'light',
  isFixed: false,
})

export const useMarketingHeaderUIContext = () => {
  return useContext(MarketingHeaderUIContext)
}
