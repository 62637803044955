import {
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'
import { selectDisplayLocale } from 'modules/i18n/reducer'
import { useAppSelector } from 'modules/redux'

type LocalizedMarketingSiteUrls = {
  homeUrl: string
  pricingUrl: string
}
export const useGetLocalizedMarketingSiteUrls =
  (): LocalizedMarketingSiteUrls => {
    const locale = useAppSelector(selectDisplayLocale)
    // If locale is english, if there's no locale, or if the locale is not supported, use the default locale
    const isDefaultLocale =
      locale === 'en' ||
      !locale ||
      !SUPPORTED_LOCALE_KEYS.includes(locale as SupportedLocaleKey)
    return {
      homeUrl: isDefaultLocale ? '/' : `/${locale}`,
      pricingUrl: isDefaultLocale ? '/pricing' : `/${locale}/pricing`,
    }
  }
