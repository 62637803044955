import gammaLogoBlack from 'publicImages/gamma-logo-black.svg'
import gammaLogoWhite from 'publicImages/gamma-logo-white.svg'

export const HEADER_BG_COLOR = {
  light: 'whiteAlpha.900',
  dark: 'blackAlpha.800',
}

export const GAMMA_LOGO_FILE = {
  light: gammaLogoBlack.src,
  dark: gammaLogoWhite.src,
}

export const LINK_COLOR = {
  light: 'blackAlpha.800',
  dark: 'whiteAlpha.800',
}

export const LINK_COLOR_HOVER = {
  light: 'black',
  dark: 'white',
}

export const COLOR_SCHEME = {
  light: 'gray',
  dark: 'whiteAlpha',
}
