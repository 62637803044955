import {
  Stack,
  Portal,
  Button,
  CloseButton,
  Flex,
  Text,
} from '@chakra-ui/react'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

import { isRobot } from 'utils/deviceDetection'
import { isPuppeteer } from 'utils/export'
import { useSSRMounted } from 'utils/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { shouldUsePublishedVersion } from 'utils/publishing'
import { localStore } from 'utils/storage'

import { Butterbars, StaticButterbar } from './butterbars'

type ButterbarManagerProps = {
  isViewOnly?: boolean
}

const isWindowDefined = typeof window !== 'undefined'

export const ButterbarManager = ({ isViewOnly }: ButterbarManagerProps) => {
  const isSSRMounted = useSSRMounted()

  // There are certain conditions where no butterbar should ever show up
  // - In view-only contexts, like /public
  // - On the server-side, or while SSR is mounting to avoid hydration errors
  // - If this is a robot (like search engine crawler)
  // - If this is puppeteer (like screenshots, PDF or PPT export)
  if (
    isViewOnly ||
    !isSSRMounted ||
    !isWindowDefined ||
    isRobot() ||
    isPuppeteer ||
    shouldUsePublishedVersion()
  ) {
    return null
  }

  const butterbars = Butterbars.map((butterbar) => {
    if ('Component' in butterbar) {
      return <butterbar.Component key={butterbar.key} />
    } else {
      return (
        <StaticButterbarComponent key={butterbar.key} definition={butterbar} />
      )
    }
  })

  return (
    <Portal>
      <Flex
        data-testid="butterbar"
        direction="column"
        top={2}
        pos={'absolute'}
        zIndex={2}
        sx={{
          // Hide all but the first rendered butterbar
          '> *:not(:first-child)': {
            display: 'none',
          },
        }}
      >
        {butterbars}
      </Flex>
    </Portal>
  )
}

type StaticButterbarProps = {
  definition: StaticButterbar
}

export const StaticButterbarComponent = ({
  definition,
}: StaticButterbarProps) => {
  const {
    key,
    dismissKey = `butterbar-dismiss-${key}`,
    header,
    text,
    buttonText,
    buttonLink,
    buttonOnClick = buttonLink
      ? () => window.open(buttonLink, '_blank')
      : undefined,
    conditions,
    ttl,
  } = definition
  const [hasLastDismissedLocal, setHasLastDismissedLocal] = useLocalStorage<
    boolean | string
  >(dismissKey, false)

  useEffect(() => {
    // Both ttl and hasLastDismissedLocal needs to work
    if (!(ttl && hasLastDismissedLocal)) return
    const now = new Date().getTime()
    const lastDismissedTime = new Date(
      hasLastDismissedLocal as string
    ).getTime()
    const isExpired = now - lastDismissedTime > ttl * 1000
    if (isExpired) {
      localStore.removeItem(dismissKey)
    }
  }, [dismissKey, hasLastDismissedLocal, ttl])

  if (conditions && !conditions()) return null

  if (hasLastDismissedLocal) return null

  const onClose = () => {
    setHasLastDismissedLocal(String(new Date()))
  }

  const hasButton = buttonText && buttonOnClick

  return (
    <Flex
      bg="indigo.500"
      color="white"
      py={3}
      px={4}
      fontSize="sm"
      borderRadius="lg"
      gap={4}
      position="fixed"
      left="50%"
      transform="translateX(-50%)"
      shadow="lg"
      width={{ base: 'calc(100% - 1rem)', md: 'auto' }}
      direction={{ base: 'column', lg: 'row' }}
    >
      <Stack
        spacing={0}
        flex={1}
        pr={{
          base: 12,
          lg: 0,
        }}
      >
        <Text fontWeight="bold">{header}</Text>
        <Text opacity="0.7" fontSize="xs">
          {text}
        </Text>
      </Stack>
      {hasButton && (
        <Button
          flexShrink={0}
          variant="plain"
          size="sm"
          rightIcon={
            buttonLink ? (
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            ) : undefined
          }
          onClick={buttonOnClick}
        >
          {buttonText}
        </Button>
      )}
      <Flex
        alignSelf="flex-start"
        position={{
          base: 'absolute',
          lg: 'static',
        }}
        right={4}
        top={4}
      >
        <CloseButton colorScheme="whiteAlpha" size="sm" onClick={onClose} />
      </Flex>
    </Flex>
  )
}
