/**
 * This component has extra logic to handle locale-aware routing for the marketing site
 */
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { LocalePickerButtonAndModal } from 'modules/i18n/components/LocalePickerButtonAndModal'
import { useLinguiLocale } from 'modules/i18n/hooks/useLinguiLocale'
import { useGetLocalizedUrlForThisPageIfAvailable } from 'modules/marketing_pages/hooks/useGetLocalizedUrlForThisPageIfAvailable'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import {
  COLOR_SCHEME,
  LINK_COLOR,
  LINK_COLOR_HOVER,
} from './Header/colorConstants'
import { useMarketingHeaderUIContext } from './Header/MarketingHeaderUIContext'

export const MarketingLanguageSwitcher = ({
  forceMode,
}: {
  forceMode?: 'dark' | 'light'
}) => {
  const { mode } = useMarketingHeaderUIContext()

  const modeToUse = forceMode || mode

  const getUrl = useGetLocalizedUrlForThisPageIfAvailable()
  const { replace } = useRouter()
  // The latest known value of your user setting locale
  const [_lsLocale, setLocalStorageLocale] = useLocalStorage<string | null>(
    USER_SETTINGS_CONSTANTS.gammaLocale,
    null
  )
  const localeKey = useLinguiLocale()

  const handleLocaleClick = useCallback(
    (localeClicked) => {
      const path = getUrl(localeClicked)
      if (path) {
        // No need to set local storage locale here; we will set it in useLinguiInit
        replace(path, undefined, { shallow: false, scroll: false })
      } else {
        setLocalStorageLocale(localeClicked)
        window.location.reload()
      }
    },
    [getUrl, replace, setLocalStorageLocale]
  )

  return (
    <LocalePickerButtonAndModal
      onLocaleSelect={handleLocaleClick}
      selectedLocaleKey={localeKey}
      buttonProps={{
        variant: 'link',
        color: LINK_COLOR[modeToUse],
        colorScheme: COLOR_SCHEME[modeToUse],
        _hover: {
          color: LINK_COLOR_HOVER[modeToUse],
        },
      }}
    />
  )
}
