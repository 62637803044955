import { Flex, ThemeProvider } from '@chakra-ui/react'
import { NextPage } from 'next'
import { useMemo } from 'react'

import {
  HeaderMode,
  MarketingHeader,
} from 'modules/marketing_pages/components/MarketingUI/components/Header/MarketingHeader'
import { MarketingHeaderUIContext } from 'modules/marketing_pages/components/MarketingUI/components/Header/MarketingHeaderUIContext'
import { MarketingFooter } from 'modules/marketing_pages/components/MarketingUI/components/MarketingFooter'
import { marketingTheme } from 'modules/marketing_pages/components/MarketingUI/marketingTheme'
import EudoxusFontLinks from 'scss/fonts/Eudoxus-Sans'

type MarketingLayoutProps = {
  children: React.ReactNode
  headerMode?: HeaderMode
  fixed?: boolean
}

export const MarketingLayout = ({
  children,
  headerMode = 'light',
  fixed,
}: MarketingLayoutProps): JSX.Element => {
  const contextState = useMemo(
    () => ({ mode: headerMode, isFixed: fixed }),
    [headerMode, fixed]
  )
  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      minHeight="var(--100vh)"
      color="gray.800"
      overflowX="hidden"
    >
      <MarketingHeaderUIContext.Provider value={contextState}>
        <MarketingHeader />
      </MarketingHeaderUIContext.Provider>
      <Flex
        zIndex="1"
        id="main"
        flex="1"
        justifyContent="flex-start"
        alignItems="center"
        direction="column"
        position="relative"
        minH="50vh"
      >
        {children}
      </Flex>
      <MarketingFooter />
    </Flex>
  )
}

type MarketingLayoutOptions = {
  headerMode?: HeaderMode
  fixed?: boolean
}
export function withMarketingLayout<T extends Record<string, any>>(
  Component: NextPage<T>,
  marketingLayoutOptions?: MarketingLayoutOptions
) {
  const WithLayoutComponent = (props: T) => {
    const { headerMode, fixed } = marketingLayoutOptions || {}
    return (
      <ThemeProvider theme={marketingTheme}>
        <EudoxusFontLinks />
        <MarketingLayout headerMode={headerMode} fixed={fixed}>
          <Component {...props} />
        </MarketingLayout>
      </ThemeProvider>
    )
  }

  if ('getInitialProps' in Component) {
    WithLayoutComponent.getInitialProps = Component.getInitialProps
  }

  return WithLayoutComponent
}
