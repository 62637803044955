import { useRouter } from 'next/router'
import { useCallback } from 'react'

import {
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'

import {
  LOCALIZED_MARKETING_SITE_PATHS,
  LocalizedMarketingSitePath,
} from '../constants'

type GetUrlReturnValue = string | null
export const useGetLocalizedUrlForThisPageIfAvailable = () => {
  const { query, asPath } = useRouter()

  const getUrl = useCallback(
    (newLocale): GetUrlReturnValue => {
      const pathWithoutQueryParams = asPath.split('?')[0]
      const pathWithoutLocalePrefix =
        query.locale === 'en'
          ? pathWithoutQueryParams
          : pathWithoutQueryParams === `/${query.locale}`
          ? '/'
          : pathWithoutQueryParams.replace(`/${query.locale}/`, '/')

      const search = asPath.split('?')[1]

      let result = pathWithoutQueryParams

      // Short-circuit if the path doesn't have a localized version
      const doesPathHaveLocalizedVersion =
        LOCALIZED_MARKETING_SITE_PATHS.includes(
          pathWithoutLocalePrefix as LocalizedMarketingSitePath
        )
      if (!doesPathHaveLocalizedVersion) {
        return null
      }

      if (SUPPORTED_LOCALE_KEYS.includes(newLocale as SupportedLocaleKey)) {
        if (newLocale === 'en') {
          result =
            pathWithoutLocalePrefix === '' ? '/' : pathWithoutLocalePrefix
        } else
          result = `/${newLocale}${
            // remove trailing slash if it's the only character
            pathWithoutLocalePrefix === '/' ? '' : pathWithoutLocalePrefix
          }`
      }
      return `${result}${search ? `?${search}` : ''}`
    },
    [asPath, query]
  )
  return getUrl
}
