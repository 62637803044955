import { Trans } from '@lingui/macro'
import React from 'react'

import { AIServiceStatusButterbar } from 'modules/ai/components/AIServiceStatusButterbar'
import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import {
  PaymentOverdueButterbar,
  SubscriptionExpiredButterbar,
  UpcomingCancellationButterbar,
} from 'modules/monetization/components/MonetizationButterbars'
import {
  isBrave,
  isChrome,
  isDesktopDevice,
  isEdgeBrowser,
  isSafari,
  isTabletDevice,
} from 'utils/deviceDetection'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { OutageButterbar } from './OutageButterbar'

export type StaticButterbar = {
  key: string
  header: JSX.Element | string
  text?: JSX.Element | string
  conditions?: () => boolean
  dismissKey?: string // The localstorage key to track whether the butterbar has been dismissed. Defaults butterbar-dismiss-${key}
  buttonText?: JSX.Element | string
  buttonLink?: string
  buttonOnClick?: () => void
  ttl?: number // Number of seconds the value of dismissKey should be stored in localstorage. Defaults to 7 days
}

export type DynamicButterbar = {
  Component: React.FC
  key: string
}

type Butterbar = StaticButterbar | DynamicButterbar

// Butterbars are sorted in priority order
// The first butterbar that matches conditions will be shown

export const Butterbars: Butterbar[] = [
  {
    Component: OutageButterbar,
    key: 'outage-butterbar',
  },
  {
    Component: AIServiceStatusButterbar,
    key: 'ai-service-status-butterbar',
  },
  {
    Component: PaymentOverdueButterbar,
    key: 'payment-overdue-butterbar',
  },
  {
    Component: SubscriptionExpiredButterbar,
    key: 'subscription-expired-butterbar',
  },
  {
    Component: UpcomingCancellationButterbar,
    key: 'upcoming-cancellation-butterbar',
  },
  {
    key: 'tablet-browser-warning',
    header: <Trans>{GAMMA_PROPER_NOUN} works best on Chrome desktop.</Trans>,
    text: (
      <Trans>
        Try opening this page on your computer for the full {GAMMA_PROPER_NOUN}{' '}
        experience.
      </Trans>
    ),
    conditions: () => isTabletDevice(),
    dismissKey: USER_SETTINGS_CONSTANTS.lastDismissedPreferredBrowserMessage,
  },
  {
    key: 'desktop-browser-warning',
    header: <Trans>{GAMMA_PROPER_NOUN} works best on Chrome.</Trans>,
    text: <Trans>For the best experience, open this page in Chrome.</Trans>,
    conditions: () => isUnsupportedBrowser() && isDesktopDevice(),
    dismissKey: USER_SETTINGS_CONSTANTS.lastDismissedPreferredBrowserMessage,
  },
]

const isUnsupportedBrowser = () =>
  isBrave() || (!isChrome() && !isSafari() && !isEdgeBrowser())
