import { Link, LinkProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import React, { ReactNode } from 'react'

import { LINK_COLOR, LINK_COLOR_HOVER } from '../colorConstants'
import { useMarketingHeaderUIContext } from '../MarketingHeaderUIContext'
export const NavbarTextLink = ({
  href,
  children,
  ...rest
}: {
  href: string
  children: ReactNode
  rest?: LinkProps
}) => {
  const { mode } = useMarketingHeaderUIContext()

  return (
    <Link
      href={href}
      as={NextLink}
      color={LINK_COLOR[mode]}
      _hover={{
        textDecoration: 'none',
        color: LINK_COLOR_HOVER[mode],
      }}
      {...rest}
    >
      {children}
    </Link>
  )
}
