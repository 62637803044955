import { t, Trans } from '@lingui/macro'

import { StaticButterbarComponent } from 'gamma_components/Butterbar/Butterbar'
import { useFeatureFlag } from 'modules/featureFlags'
import { isCreateV2Path } from 'utils/url'

export const AIServiceStatusButterbar = () => {
  const openAiStatus = useFeatureFlag('openAiStatus')

  if (openAiStatus === 'NORMAL') {
    return null
  }
  return (
    <StaticButterbarComponent
      definition={{
        key: `ai-service-status`,
        header: <Trans>Our AI generator is experiencing issues</Trans>,
        text: (
          <Trans>
            We're sorry, our AI service may be slow or unavailable. We’re
            investigating. Please check back later.
          </Trans>
        ),
        // Worst case scenario: If the user dismisses the butterbar,
        // we'll show it again in 1 day in case the issue is still ongoing.
        ttl: 60 * 60 * 24, // 1 day in seconds
        conditions: () => {
          return isCreateV2Path(window.location.pathname)
        },
      }}
    />
  )
}
