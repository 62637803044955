import {
  ButtonProps,
  extendTheme,
  ThemeOverride,
  withDefaultColorScheme,
} from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
// 1. Import `extendTheme`
import { gammaTheme } from '@gamma-app/ui'

const MARKETING_GRADIENTS = {
  fancy: {
    light:
      'linear-gradient(79deg, #2b0aff, #ff5b8a 49%, #ff5b8a 55%, #fba64b 77%, #f99b52)',
    dark: 'radial-gradient(circle at -20% 0, #2b0aff, #e93abf 31%, #f658a8 48%, #febf72)',
  },
}
// 2. Call `extendTheme` and pass your custom values
export const overrides: ThemeOverride = {
  colors: gammaTheme.colors,
  zIndices: {
    tooltip: 5600, // Force this to be higher than the toast manager
    modal: 1800, // Force this to go over popovers
    dropdown: 1600, // Force this to go over popovers
  },
  fonts: {
    body: "'Eudoxus Sans', sans-serif",
    heading: "'p22-mackinac-pro', sans-serif",
  },
  fontSizes: {
    xxs: '11px',
  },
  components: {
    Link: {
      baseStyle: {
        textDecoration: 'none',
      },
    },
    Button: {
      sizes: {
        xl: {
          h: 14,
          fontSize: 'lg',
          px: 8,
        },
      },
      variants: {
        plain: (props: ButtonProps) => ({
          color: mode('gray.800', 'gray.100')(props),
          shadow: 'md',
          bg: mode('gradient.light', 'gradient.dark')(props),
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: mode('gray.200', 'gray.800')(props),
          _hover: {
            color: mode('trueblue.500', 'trueblue.200')(props),
          },
          _active: {
            shadow: 'inner',
          },
        }),
        fancy: {
          shadow: 'md',
          bg: MARKETING_GRADIENTS.fancy.light,
          textShadow: '0px 1px 2px rgba(0,0,0,.5)',
          color: 'gray.100',
          backgroundSize: '400%',
          transitionProperty: 'all',
          transitionDuration: 'normal',
          _hover: {
            filter: 'brightness(120%)',
            backgroundSize: '200%',
            bg: MARKETING_GRADIENTS.fancy.light,
            textDecoration: 'none !important',
          },
          '&[disabled]': {
            _hover: {
              bg: MARKETING_GRADIENTS.fancy.light,
              backgroundSize: '400%',
              textDecoration: 'none !important',
            },
          },
          _active: {
            bg: MARKETING_GRADIENTS.fancy.light,
            shadow: 'md-inner',
            filter: 'brightness(90%)',
            backgroundSize: '200%',
            textDecoration: 'none !important',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        textWrap: 'pretty',
      },
    },
    Heading: {
      sizes: {
        '5xl': {
          fontSize: { base: '4.5rem' },
          lineHeight: '1',
          fontWeight: '500',
        },
        '6xl': {
          fontSize: { base: '5rem' },
          lineHeight: '1',
          fontWeight: '500',
        },
      },
      baseStyle: {
        letterSpacing: '-0.04em',
        textWrap: 'pretty',
      },
      variants: {
        fancy: {
          backgroundImage: MARKETING_GRADIENTS.fancy.light,
          backgroundClip: 'text',
          color: 'transparent',
          fontWeight: '600',
          lineHeight: '1.1',
        },
        fancyDark: {
          backgroundImage: MARKETING_GRADIENTS.fancy.dark,
          backgroundClip: 'text',
          color: 'transparent',
          lineHeight: '1.1',
        },
        sansSerif: {
          fontFamily: 'var(--body-font)',
          letterSpacing: 'normal',
          fontWeight: 'regular',
        },
      },
    },
  },
}

export const marketingTheme = extendTheme(
  overrides,
  withDefaultColorScheme({ colorScheme: 'trueblue' })
)
