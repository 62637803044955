import { Link } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { add, parseISO } from 'date-fns'

import { StaticButterbarComponent } from 'gamma_components/Butterbar/Butterbar'
import { useChurnkeyFailedPaymentCheck } from 'modules/churnkey'
import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import { formatDate } from 'modules/i18n/utils/date'
import { SegmentEvents } from 'modules/segment'
import { useUserContext } from 'modules/user'

import { useMonetizationContext } from '../context'
import { getProductDetails } from './UpsellModal/constants'
import { getCustomerPortalURL } from './UpsellModal/utils'

const RECENTLY_EXPIRED_DAYS = 10
const CANCELLING_SOON_DAYS = 7

export const PaymentOverdueButterbar = () => {
  const { user, currentWorkspace } = useUserContext()
  const { subscription, product } = useMonetizationContext()
  const { launchFailedPaymentFlow, failedPaymentFlowEnabled } =
    useChurnkeyFailedPaymentCheck({
      subscription,
      workspace: currentWorkspace,
      autoLaunch: false,
    })

  if (!subscription || !product) return null

  const productDetails = getProductDetails()
  const { name: productName, dontChurnMessage } = productDetails[product]

  const { status, expirationTime, id } = subscription
  // todo: maybe hide this if it's very far out (a yearly subscription)
  const expirationFormatted = formatDate(expirationTime, 'MMMM do, yyyy')

  const isBillingAdmin = subscription?.createdByUser?.id === user?.id
  const manageSubscriptionUrl = getCustomerPortalURL()

  const isOverdue = status === 'unpaid' || status === 'past_due'
  const isExpired = expirationTime > Date.now()

  if (!isOverdue && !isExpired) return null

  const cta =
    manageSubscriptionUrl &&
    (isBillingAdmin || !subscription?.createdByUser) ? (
      <Trans>
        <Link
          href={failedPaymentFlowEnabled ? undefined : manageSubscriptionUrl}
          onClick={
            failedPaymentFlowEnabled ? launchFailedPaymentFlow : undefined
          }
          isExternal
          textDecoration="underline"
        >
          Update your billing information
        </Link>{' '}
        before {expirationFormatted} to continue your {GAMMA_PROPER_NOUN}{' '}
        {productName} subscription.
      </Trans>
    ) : (
      <Trans>
        Contact your billing admin{' '}
        <b>{subscription.createdByUser?.displayName}</b> (
        {subscription.createdByUser?.email}) before <b>{expirationFormatted}</b>{' '}
        to continue your {GAMMA_PROPER_NOUN} {productName} subscription.
      </Trans>
    )

  return (
    <StaticButterbarComponent
      definition={{
        key: `payment-overdue-${id}`,
        header: (
          <Trans>We couldn't process your Gamma {productName} payment.</Trans>
        ),
        text: (
          <>
            {dontChurnMessage} {cta}
          </>
        ),
        buttonText: <Trans>Update billing</Trans>,
        buttonLink: failedPaymentFlowEnabled
          ? undefined
          : manageSubscriptionUrl,
        buttonOnClick: failedPaymentFlowEnabled
          ? launchFailedPaymentFlow
          : undefined,
      }}
    />
  )
}

export const SubscriptionExpiredButterbar = () => {
  const { subscription, openUpsellModal } = useMonetizationContext()
  if (!subscription) return null
  const { status, expirationTime, id } = subscription

  const isRecentlyExpired =
    new Date() > parseISO(expirationTime) &&
    new Date() < add(parseISO(expirationTime), { days: RECENTLY_EXPIRED_DAYS })

  if (!isRecentlyExpired) return null

  const wasPaymentFail = status === 'unpaid' || status === 'past_due'

  const onClick = () =>
    openUpsellModal({
      segmentEvent:
        SegmentEvents.GAMMA_PRO_UPSELL_SUBSCRIPTION_EXPIRED_BUTTERBAR,
    })
  const cta = (
    <Link onClick={onClick} isExternal textDecoration="underline">
      <Trans>Upgrade to re-activate your subscription.</Trans>
    </Link>
  )

  return (
    <StaticButterbarComponent
      definition={{
        key: `subscription-expired-${id}`,
        header: <Trans>Your Gamma subscription has ended.</Trans>,
        text: (
          <>
            {wasPaymentFail ? (
              <Trans>We were unable to process your payment information.</Trans>
            ) : (
              ''
            )}{' '}
            {cta}
          </>
        ),
        buttonText: <Trans>Upgrade</Trans>,
        buttonOnClick: onClick,
      }}
    />
  )
}

export const UpcomingCancellationButterbar = () => {
  const { user } = useUserContext()
  const { subscription, product } = useMonetizationContext()
  if (!subscription || !product) return null
  const { status, expirationTime, id, cancellationRequestedTime } = subscription

  const isUpcomingCancellation =
    status != 'canceled' &&
    cancellationRequestedTime &&
    new Date() < parseISO(expirationTime) &&
    new Date() >
      add(parseISO(expirationTime), { days: 0 - CANCELLING_SOON_DAYS })

  if (!isUpcomingCancellation) return null

  const isBillingAdmin = subscription?.createdByUser?.id === user?.id
  const manageSubscriptionUrl = getCustomerPortalURL()
  const expirationFormatted = formatDate(expirationTime, 'MMMM do, yyyy')

  const productDetails = getProductDetails()
  const { name: productName, dontChurnMessage } = productDetails[product]

  const cta =
    isBillingAdmin || !subscription?.createdByUser ? (
      <Trans>
        <Link
          href={manageSubscriptionUrl}
          isExternal
          textDecoration="underline"
        >
          Renew your subscription
        </Link>{' '}
        before {expirationFormatted} to keep your {GAMMA_PROPER_NOUN}{' '}
        {productName} access.
      </Trans>
    ) : (
      <Trans>
        Contact your billing admin{' '}
        <b>{subscription.createdByUser?.displayName}</b> (
        {subscription.createdByUser?.email}) before <b>{expirationFormatted}</b>{' '}
        to keep your {GAMMA_PROPER_NOUN} {productName} access.
      </Trans>
    )

  return (
    <StaticButterbarComponent
      definition={{
        key: `subscription-ending-soon-${id}`,
        header: (
          <Trans>Your Gamma {productName} subscription is ending soon.</Trans>
        ),
        text: (
          <>
            {dontChurnMessage} {cta}
          </>
        ),
        buttonText: <Trans>Manage subscription</Trans>,
        buttonLink: manageSubscriptionUrl,
      }}
    />
  )
}
